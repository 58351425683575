//import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import {
  postUser,
  getUserProfile,
  getUserList
} from "@/services/api/workUsers";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormCheckboxGroup,
  BInputGroup,
  BSpinner,
  BInputGroupText,
  BInputGroupAppend,
  BAlert
} from "bootstrap-vue";

export default {
  name: "adminsubscription",
  data() {
    return {};
  },
  validations: {
    form: {
      address: { required }
    }
  },
  components: {
    "b-alert": BAlert,
    "b-form": BForm,
    "b-form-group": BFormGroup,
    "b-form-input": BFormInput,
    "b-form-checkbox": BFormCheckbox,
    "b-button": BButton,
    "b-form-checkbox-group": BFormCheckboxGroup,
    "b-input-group": BInputGroup,
    "b-spinner": BSpinner,
    "b-input-group-append": BInputGroupAppend,
    "b-input-group-text": BInputGroupText
  },
  created() {
    console.info("CREATED workUsers form");
    //this.form = this.$store.getters["assignment/assignmentForm"];
  },
  mounted() {
    console.info("Mounted:", this.form.address); //, this.$refs["shortDescription"]);
    // autosize(this.$refs["shortDescription"]);
  },
  watch: {},
  computed: {
    //...mapFields(["formAssignment"]), //.title", "form.weight"]),
    /*id: () => {
      return this.$route.params.id;
    }*/
  },
  methods: {
    postUser,
    getUserProfile,
    getUserList
  }
};
